import React from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap';
import tour1 from '../images/tour-1.jpg';
import tour2 from '../images/tour-2.jpg';
import PageBanner from '../common/PageBanner'
import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import room from '../images/single-room4.jpg';
import room2 from '../images/single-room5.jpg';
import room3 from '../images/single-room6.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default function RoomDetails() {
  const options = {
    margin: 30,
    responsiveClass: true,
    nav: true,
    dots: false,
    autoplay: false,
    navText: ["<i class='fa-solid fa-arrow-left-long'></i>", "<i class='fa-solid fa-arrow-right-long'></i>"],
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 1,
      },
      1000: {
        items: 1,

      }
    },
  };
  return (
    <React.Fragment>
      <PageBanner title="About us" />
      <div className='about_home page_s'>
        <Container>
          <Row>
            <Col md="8">
              <OwlCarousel className="slider-items owl-carousel room_detail_img owl-theme" {...options}>
                <div className="item article-items">
                  <img src={room} alt='' />
                </div>
                <div className="item article-items">
                  <img src={room2} alt='' />
                </div>
                <div className="item article-items">
                  <img src={room3} alt='' />
                </div>
              </OwlCarousel>
              <div className='mt-4'>
                <h4>ABOUT HOTEL</h4>
                <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis</p>
                <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis</p>
                <h4>Room Facilities</h4>
                <div className='list_unstyled'>
                  <ul>
                    <li><FontAwesomeIcon icon="fa-solid fa-check" className='text-success' /> Double Bed</li>
                    <li><FontAwesomeIcon icon="fa-solid fa-check" className='text-success' /> 80 Sq mt</li>
                    <li><FontAwesomeIcon icon="fa-solid fa-check" className='text-success' /> 6 Persons</li>
                    <li><FontAwesomeIcon icon="fa-solid fa-check" className='text-success' /> Free Internet</li>
                    <li><FontAwesomeIcon icon="fa-solid fa-check" className='text-success' /> Free Wi-Fi</li>
                    <li><FontAwesomeIcon icon="fa-solid fa-check" className='text-success' /> Breakfast Include</li>
                    <li><FontAwesomeIcon icon="fa-solid fa-check" className='text-success' /> Private Balcony</li>
                    <li><FontAwesomeIcon icon="fa-solid fa-xmark" className='text-danger' /> Free Newspaper</li>
                    <li><FontAwesomeIcon icon="fa-solid fa-xmark" className='text-danger' /> Flat Screen Tv</li>
                    <li><FontAwesomeIcon icon="fa-solid fa-check" className='text-success' /> Full Ac</li>
                    <li><FontAwesomeIcon icon="fa-solid fa-check" className='text-success' /> Beach View</li>
                     <li><FontAwesomeIcon icon="fa-solid fa-check" className='text-success' /> Room Service</li>
                  </ul>
                </div>
              </div>
            </Col>
            <Col md="4">
              <Card>
                <Card.Body>
                  <div className='tour_style'>
                    <h3>ROOMS PACKAGES</h3>
                    <Row>
                      <Col md={12} className='mb-4'>
                        <div className='tour_bx'>
                          <img src={tour1} alt='' />
                          <div className='tour_hover'>
                            <div className="gallery-inner">
                              <span><strong className="amount">₹93.00 </strong>/ Extra</span>
                              <h5>Deluxe Room</h5>
                              <Link className="read_more dark_btn" to="#" title="">View Detail</Link>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col md={12} className='mb-4'>
                        <div className='tour_bx'>
                          <img src={tour2} alt='' />
                          <div className='tour_hover'>
                            <div className="gallery-inner">
                              <span><strong className="amount">₹93.00 </strong>/ Extra</span>
                              <h5>Royal Club</h5>
                              <Link className="read_more dark_btn" to="#" title="">View Detail</Link>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Card.Body>
              </Card>

            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}