import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import img1 from '../images/about2-1.jpg'
import img2 from '../images/about2-2.jpg'
import imgIcon from '../images/aicon.png'
import lamp from '../images/lamp.png'
import building from '../images/building.png'
import { Link } from 'react-router-dom'
export default function AboutHome() {
    return (
        <React.Fragment>
            <div className='about_home'>
                <Container>
                    <Row className='align-items-center'>
                        <Col md={6}>
                            <div>
                                <div className='title_dark'>
                                    <span className='sub_title'>HOEXR LUXURY HOTEL</span>
                                    <h1>We Provide Outdoor Activities To All Visitors</h1>
                                </div>
                                <p>San Francisco has hills with views, the coast, excellent food &
                                    has been voted the happiest, healthiest and fittest city in the
                                    States many times.</p>
                            </div>
                            <div className='inn_ser'>
                            <Row>
                                <Col md={6}>
                                    <div className='d-flex gap-3 align-items-center'>
                                        <img src={lamp} alt="" />
                                        <h4>The Best Lighting</h4>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className='d-flex gap-3 align-items-center'>
                                        <img src={building} alt="" />
                                        <h4>The Best Swiming</h4>
                                    </div>
                                </Col>
                                </Row>
                            </div>
                            <div className='about_list mb-4'>
                                <ul>
                                    <li>It is a long fact that a reader will be distracted by the readable</li>
                                    <li>Lorem Ipsum is simply dummy of the printing and industry</li>
                                    <li>There are many variations of Lorem Ipsum majority</li>
                                </ul>
                            </div>
                            <Link to="#" className='btn rounded-0 dark_btn'>Read More</Link>
                        </Col>
                        <Col md={6}>
                            <div className="inner_column">
                                <figure className="image-1 overlay-anim">
                                    <img src={img1} alt=""/></figure>
                                <figure className="image-2">
                                    <img src={img2} alt="" />
                                </figure>
                                <div className="exp-box bounce-y">
                                    <figure className="">
                                        <img src={imgIcon} alt="" />
                                    </figure>
                                    <h4 className="title">Luxury Room</h4>
                                    <div className="text">Donec in quis the asd <br />pellentesque velit. Donec id <br />velit arcu posuere blane.</div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}