import React from 'react'
import Banner from './Banner'
import AboutHome from './AboutHome'
import Rooms from './Rooms'
import Tours from './Tours'
import Testimonials from './Testimonials'
import Gallery from './Gallery'

export default function Home() {
  return (
    <React.Fragment>
      <Banner/>
      <AboutHome />
      <Rooms />
      <Tours />
      <Testimonials />
      <Gallery/>
    </React.Fragment>
  )
}