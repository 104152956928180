import React from 'react'
import { Container  } from 'react-bootstrap'
import gallery from '../images/food-list.jpg';
import gallery2 from '../images/food-list-2.jpg';
import gallery3 from '../images/5-product.jpg';
import gallery4 from '../images/2-product.jpg';
import PageBanner from '../common/PageBanner';
// import { initLightboxJS } from 'lightbox.js-react'
import { SlideshowLightbox } from 'lightbox.js-react'

export default function PhotoGallery() {

    return (
        <React.Fragment>
            <PageBanner title="Photo Gallery" />
            <div className='py-5 gallery_styles'>
                <Container>
                   
                    <SlideshowLightbox className='row  row-cols-2 row-cols-md-3 row-cols-lg-4 gallery_img'>
                        <img className="img-fluid mb-4 rounded" src={gallery} alt='' />
                        <img className="img-fluid mb-4 rounded" src={gallery2} alt='' />
                        <img className="img-fluid mb-4 rounded" src={gallery4} alt='' />
                        <img className="img-fluid mb-4 rounded" src={gallery3} alt='' />
                        <img className="img-fluid mb-4 rounded" src={gallery4} alt='' />
                        <img className="img-fluid mb-4 rounded" src={gallery} alt='' />
                        <img className="img-fluid mb-4 rounded" src={gallery2} alt='' />
                        <img className="img-fluid mb-4 rounded" src={gallery3} alt='' />
                    </SlideshowLightbox> 
                    
                </Container>
            </div>
        </React.Fragment>
    )
}