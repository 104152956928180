import React, { useState, useEffect } from 'react';

const UniqueVisitorCounter = () => {
    const [uniqueVisitors, setUniqueVisitors] = useState(0);

    useEffect(() => {
        // Check if there is a unique visitors count in local storage
        const storedUniqueVisitors = localStorage.getItem('uniqueVisitors');

        // If there is a count in local storage, set the state
        if (storedUniqueVisitors) {
            setUniqueVisitors(parseInt(storedUniqueVisitors));
        } else {
            // Otherwise, set initial value to 0
            localStorage.setItem('uniqueVisitors', '0');
            setUniqueVisitors(0);
        }

        // Increment the unique visitors count and update local storage
        setUniqueVisitors(prevCount => {
            const updatedCount = prevCount + 1;
            localStorage.setItem('uniqueVisitors', updatedCount.toString());
            return updatedCount;
        });
    }, []);

    return (
        <div>
            <p>Number of unique visitors:</p>
            <span className='text-center' style={{fontSize:22, letterSpacing:15, fontWeight:600,}}>{uniqueVisitors}</span>
        </div>
    );
};

export default UniqueVisitorCounter;
