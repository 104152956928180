import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import OwlCarousel from 'react-owl-carousel';
import room from '../images/room.jpg';
import room2 from '../images/room2.jpg';
import { Link } from 'react-router-dom';
export default function Rooms() {
  const options = {
    margin: 30,
    responsiveClass: true,
    nav: true,
    dots: false,
    autoplay: false,
    navText: ["Prev", "Next"],
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 2,
      },
      1000: {
        items: 2,

      }
    },
  };

  return (
    <React.Fragment>
      <div className='py-5'>
        <Container>
          <Row className="justify-content-center mb-3">
            <Col md={6}>
              <div className='title_dark text-center'>
                <span className='sub_title'>BEST PRICE</span>
                <h2>The Best Luxury Rooms And Suites</h2>
              </div>
            </Col>
          </Row>
          <div className='room_style'>
            <OwlCarousel className="slider-items owl-carousel" {...options}>
              <div className="item">
                <div className='inner-box'>
                  <Row className='align-items-center'>
                    <Col md={4}>
                      <div className='inner_text'>
                        <div className='r_price'>
                          <h6>₹1200<span>/Night</span></h6>
                          <span className='title_h'>Single Room</span>
                        </div>
                        <ul className='list_room'>
                          <li>Pick & Drop Service</li>
                          <li>Swimming Pool</li>
                          <li>City Tour Guide</li>
                          <li>Fibre Internet</li>
                        </ul>
                        <Link to="/room-details" className='btn dark_btn border-0 rounded-0 mt-3'>Book Now</Link>
                      </div>
                    </Col>
                    <Col md={8}><img src={room} alt='' /></Col>
                  </Row>
                </div>
              </div>
              <div className="item">
                <div className='inner-box'>
                  <Row className='align-items-center'>
                    <Col md={4}>
                      <div className='inner_text'>
                        <div className='r_price'>
                          <h6>₹1200<span>/Night</span></h6>
                          <span className='title_h'>Double Room</span>
                        </div>
                        <ul className='list_room'>
                          <li>Pick & Drop Service</li>
                          <li>Swimming Pool</li>
                          <li>City Tour Guide</li>
                          <li>Fibre Internet</li>
                        </ul>
                        <Link to="/room-details" className='btn dark_btn border-0 rounded-0 mt-3'>Book Now</Link>
                      </div>
                    </Col>
                    <Col md={8}><img src={room2} alt='' /></Col>
                  </Row>
                </div>
              </div>
            </OwlCarousel>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}