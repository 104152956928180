import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import tour1 from '../images/tour-1.jpg';
import tour2 from '../images/tour-2.jpg';
import tour3 from '../images/tour-3.jpg';
import { Link } from 'react-router-dom';
import PageBanner from '../common/PageBanner';
export default function ToursPage() {
    return (
        <React.Fragment>
            <PageBanner title="Places To Visit" />
            <div className='py-5'>
                <Container>
                    <div className='tour_style'>
                        <Row>
                            <Col md={4} className='mb-4'>
                                <div className='tour_bx'>
                                    <img src={tour1} alt='' />
                                    <div className='tour_hover'>
                                        <div className="gallery-inner">
                                            <span><strong className="amount">₹93.00 </strong>/ Extra</span>
                                            <h5>WATER RAFTING</h5>
                                            <Link className="read_more dark_btn" to="#" title="">View Detail</Link>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col md={4} className='mb-4'>
                                <div className='tour_bx'>
                                    <img src={tour2} alt='' />
                                    <div className='tour_hover'>
                                        <div className="gallery-inner">
                                            <span><strong className="amount">₹93.00 </strong>/ Extra</span>
                                            <h5>WATER RAFTING</h5>
                                            <Link className="read_more dark_btn" to="#" title="">View Detail</Link>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col md={4} className='mb-4'>
                                <div className='tour_bx'>
                                    <img src={tour3} alt='' />
                                    <div className='tour_hover'>
                                        <div className="gallery-inner">
                                            <span><strong className="amount">₹93.00 </strong>/ Extra</span>
                                            <h5>WATER RAFTING</h5>
                                            <Link className="read_more dark_btn" to="tour-detail.html" title="">View Detail</Link>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col md={4} className='mb-4'>
                                <div className='tour_bx'>
                                    <img src={tour3} alt='' />
                                    <div className='tour_hover'>
                                        <div className="gallery-inner">
                                            <span><strong className="amount">₹93.00 </strong>/ Extra</span>
                                            <h5>WATER RAFTING</h5>
                                            <Link className="read_more dark_btn" to="tour-detail.html" title="">View Detail</Link>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col md={4} className='mb-4'>
                                <div className='tour_bx'>
                                    <img src={tour1} alt='' />
                                    <div className='tour_hover'>
                                        <div className="gallery-inner">
                                            <span><strong className="amount">₹93.00 </strong>/ Extra</span>
                                            <h5>WATER RAFTING</h5>
                                            <Link className="read_more dark_btn" to="#" title="">View Detail</Link>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col md={4} className='mb-4'>
                                <div className='tour_bx'>
                                    <img src={tour2} alt='' />
                                    <div className='tour_hover'>
                                        <div className="gallery-inner">
                                            <span><strong className="amount">₹93.00 </strong>/ Extra</span>
                                            <h5>WATER RAFTING</h5>
                                            <Link className="read_more dark_btn" to="#" title="">View Detail</Link>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                          
                        </Row>
                    </div>
                </Container>
            </div>
        </React.Fragment>
  )
}
