import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import gallery from '../images/food-list.jpg';
import gallery2 from '../images/food-list-2.jpg';
import gallery3 from '../images/5-product.jpg';
import gallery4 from '../images/2-product.jpg';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default function Gallery() {

    return (
        <React.Fragment>
            <div className='py-5 gallery_styles'>
                <Container>
                    <Row className="justify-content-center mb-3">
                        <Col md={6}>
                            <div className='title_dark text-center'>
                                <span className='sub_title'>Moments Framed:</span>
                                <h2>Your Photo Gallery</h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3} className='mb-4'><div className='gallery_inn'><img src={gallery} alt='' /></div></Col>
                        <Col md={3} className='mb-4'><div className='gallery_inn'><img src={gallery2} alt='' /></div></Col>
                        <Col md={3} className='mb-4'><div className='gallery_inn'><img src={gallery3} alt='' /></div></Col>
                        <Col md={3} className='mb-4'><div className='gallery_inn'><img src={gallery2} alt='' /></div></Col>
                        <Col md={3} className='mb-4'><div className='gallery_inn'><img src={gallery4} alt='' /></div></Col>
                        <Col md={3} className='mb-4'><div className='gallery_inn'><img src={gallery} alt='' /></div></Col>
                        <Col md={3} className='mb-4'><div className='gallery_inn'><img src={gallery2} alt='' /></div></Col>
                        <Col md={3} className='mb-4'><div className='gallery_inn'><img src={gallery3} alt='' /></div></Col>
                    </Row>
                    <div className='text-center'><Link to="#" className='btn dark_btn rounded-0'>View All <FontAwesomeIcon icon="fa-solid fa-arrow-right-long" /></Link></div>
                </Container>
            </div>
        </React.Fragment>
    )
}