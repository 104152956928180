import React from 'react'
import PageBanner from '../common/PageBanner'
import room from '../images/room.jpg';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default function RoomsPage() {
  return (
    <React.Fragment>
      <PageBanner title="Rooms" />
      <div className='room_style py-5'>
        <Container>
          <Row>
            <Col md={6} className='mb-5'>
              <div className='inner-box'>
                <Row className='align-items-center'>
                  <Col md={8} className='order-md-2'><img src={room} alt='' /></Col>
                  <Col md={4} >
                    <div className='inner_text'>
                      <div className='r_price'>
                        <h6>₹1200<span>/Night</span></h6>
                        <span className='title_h'>Single Room</span>
                      </div>
                      <ul className='list_room'>
                        <li>Pick & Drop Service</li>
                        <li>Swimming Pool</li>
                        <li>City Tour Guide</li>
                        <li>Fibre Internet</li>
                      </ul>
                      <Link to="/room-details" className='btn dark_btn border-0 rounded-0 mt-3'>Book Now</Link>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={6} className='mb-5'>
              <div className='inner-box'>
                <Row className='align-items-center'>
                  <Col md={8} className='order-md-2'><img src={room} alt='' /></Col>
                  <Col md={4} >
                    <div className='inner_text'>
                      <div className='r_price'>
                        <h6>₹1200<span>/Night</span></h6>
                        <span className='title_h'>Single Room</span>
                      </div>
                      <ul className='list_room'>
                        <li>Pick & Drop Service</li>
                        <li>Swimming Pool</li>
                        <li>City Tour Guide</li>
                        <li>Fibre Internet</li>
                      </ul>
                      <Link to="/room-details" className='btn dark_btn border-0 rounded-0 mt-3'>Book Now</Link>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}