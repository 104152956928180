import React from 'react'
import { Breadcrumb, Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export default function PageBanner(props) {
    return (
        <React.Fragment>
            <div className='border4'></div>
            <div className='page_banenr'>
                <Container>
                    <h1>{props.title}</h1>
                    <Breadcrumb>
                        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>Home</Breadcrumb.Item>
                        <Breadcrumb.Item active>{props.title}</Breadcrumb.Item>
                    </Breadcrumb>
                </Container>
            </div>
        </React.Fragment>
    )
}