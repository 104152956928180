import React from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import PageBanner from '../common/PageBanner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function Contact() {
    return (
        <React.Fragment>
            <PageBanner title="Contact us" />
            
            <div className='about_home page_s'>
                <Container>
                    <Row>
                        <Col md={7} className='mb-5'>
                            <div className='page_content'>
                                <div className='title_dark'>
                                    <span className='sub_title'>SEND US EMAIL</span>
                                    <h1>Feel free to write</h1>
                                </div>
                                <Form>
                                    <Row>
                                        <Form.Group className="mb-3 col-md-6" controlId="">
                                            {/* <Form.Label>Email address</Form.Label> */}
                                            <Form.Control type="text" placeholder="Enter Name" />
                                        </Form.Group>
                                        <Form.Group className="mb-3 col-md-6" controlId="">
                                            {/* <Form.Label>Email address</Form.Label> */}
                                            <Form.Control type="email" placeholder="Email address" />
                                        </Form.Group>
                                        <Form.Group className="mb-3 col-md-6" controlId="">
                                            {/* <Form.Label>Email address</Form.Label> */}
                                            <Form.Control type="text" placeholder="Enter Subject" />
                                        </Form.Group>
                                        <Form.Group className="mb-3 col-md-6" controlId="">
                                            {/* <Form.Label>Email address</Form.Label> */}
                                            <Form.Control type="text" placeholder="Enter Phone" />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="">
                                            <Form.Control as="textarea" placeholder="Enter Message" rows={5} />
                                        </Form.Group>
                                    </Row>
                                    
                                    <Button className='btn dark_btn' type="submit">
                                        Send message
                                    </Button>
                                </Form>
                            </div>
                        </Col>
                        <Col md={5} className='mb-5'>
                            <div className='page_content'>
                                <div className='title_dark'>
                                    <span className='sub_title'>NEED ANY HELP?</span>
                                    <h2>Whire with us</h2>
                                </div>
                                <p>Lorem ipsum is simply free text available dolor sit amet consectetur notted adipisicing elit sed do eiusmod tempor incididunt simply dolore magna.</p>
                                <div className='d-flex gap-2 align-items-center mb-4'>
                                    <div className='icon_contact'><FontAwesomeIcon icon="fa-solid fa-headphones-simple" /></div>
                                    <div>
                                        <h5>Have any question?</h5>
                                        <p>+92 (020)-9850</p>
                                    </div>
                                </div>
                                <div className='d-flex gap-2 align-items-center mb-4'>
                                    <div className='icon_contact'><FontAwesomeIcon icon="fa-solid fa-envelope-open-text" /></div>
                                    <div>
                                        <h5>Write email</h5>
                                        <p>needhelp@company.com</p>
                                    </div>
                                </div>
                                <div className='d-flex gap-2 align-items-center'>
                                    <div className='icon_contact'><FontAwesomeIcon icon="fa-solid fa-envelope-open-text" /></div>
                                    <div>
                                        <h5>Visit anytime</h5>
                                        <p>66 broklyn golden street. New York</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='border4'></div>
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d115408.22405537314!2d82.99110895!3d25.32076265!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x398e2db76febcf4d%3A0x68131710853ff0b5!2sVaranasi%2C%20Uttar%20Pradesh!5e0!3m2!1sen!2sin!4v1716205273381!5m2!1sen!2sin"
                height="450"
                style={{ border: 0, width: '100%' }}
                allowFullScreen={true}
                loading="lazy"
                title="Unique Title"
                referrerPolicy="no-referrer-when-downgrade"
            />
        </React.Fragment>
    )
}