import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import logo from '../images/logo.png';
import { Col, Container, Nav, Navbar, Offcanvas, Row } from 'react-bootstrap'
import { NavLink } from 'react-router-dom';
export default function HeaderPage() {
  const [menushow, setMenushow] = useState(false);
  const handleMenuclose = () => setMenushow(false);
  const handleMenushow = () => setMenushow(true);
  return (
    <React.Fragment>
      <div className='head_top'>
        <Container>
          <Row>
            <Col className='col d-none d-md-block'><FontAwesomeIcon icon="fa-solid fa-signs-post" /> Manhattan square. 124 avenue. Bodrum</Col>
            <Col className='col-auto'>
              <ul className='d-flex gap-3'>
                <li><FontAwesomeIcon icon="fa-solid fa-phone-volume" /> 1800-121-3637</li>
                <li><FontAwesomeIcon icon="fa-solid fa-envelope-open-text" /> info@example.com</li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
      <Navbar key="lg" expand="lg" className="navbar-light bg-light menu_style">
        <Container>
          <Navbar.Brand as={NavLink} to="/"><img src={logo} alt='' /></Navbar.Brand>
          <Navbar.Toggle aria-controls={'offcanvasNavbarheader'} onClick={handleMenushow} />
          <Navbar.Offcanvas
            id={'offcanvasNavbarheader'}
            aria-labelledby={'offcanvasNavbarLheader'}
            placement="start"
            show={menushow}
            onHide={handleMenuclose}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={'offcanvasNavbarLheader'}>
                <img src={logo} alt='' />
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-end flex-grow-1">
                <Nav.Link onClick={handleMenuclose} as={NavLink} to="/">Home</Nav.Link>
                <Nav.Link onClick={handleMenuclose} as={NavLink} to="/about-us">About Us</Nav.Link>
                <Nav.Link onClick={handleMenuclose} as={NavLink} to="/banquet-hall">Banquet Hall</Nav.Link>
                <Nav.Link onClick={handleMenuclose} as={NavLink} to="/rooms">Rooms</Nav.Link>
                <Nav.Link onClick={handleMenuclose} as={NavLink} to="/places-to-visit">Places To Visit</Nav.Link>
                <Nav.Link onClick={handleMenuclose} as={NavLink} to="/photo-gallery">Gallery</Nav.Link>
                <Nav.Link onClick={handleMenuclose} as={NavLink} to="/contact-us">Reach us</Nav.Link>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </React.Fragment>
  )
}