import './App.css';

import Footer from './common/Footer';
import { Route, Routes } from 'react-router-dom';
import Home from './home/Home';
import About from './pages/About';
import HeaderPage from './common/HeaderPage';
import Contact from './pages/Contact';
import RoomsPage from './pages/RoomsPage';
import RoomDetails from './pages/RoomDetails';
import BanquetHall from './pages/BanquetHall';
import PhotoGallery from './pages/PhotoGallery';
import ToursPage from './pages/ToursPage';
function App() {
  return (
    <div className="App">
      <HeaderPage />
      <Routes>
        <Route exact={"true"} path="/" element={<Home />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/rooms" element={<RoomsPage />} />
        <Route path="/photo-gallery" element={<PhotoGallery />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/room-details" element={<RoomDetails />} />
        <Route path="/banquet-hall" element={<BanquetHall />} />
        <Route path="/places-to-visit" element={<ToursPage />} />
      </Routes>
      <Footer />
    </div>  
  );
}
export default App;
