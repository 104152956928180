import React from 'react'
import PageBanner from '../common/PageBanner'
import { Card, Col, Container, Row } from 'react-bootstrap';
import tour1 from '../images/tour-1.jpg';
import tour2 from '../images/tour-2.jpg';
import { Link } from 'react-router-dom';

export default function BanquetHall() {
    return (
        <React.Fragment>
            <PageBanner title="Banquet Hall" />
            <div className='page_style py-5'>
                <Container>
                    <Row>
                        <Col md="8">
                            <h4>Welcome to [Banquet Hall Name]</h4>
                            <p>Creating Memorable Moments</p>
                            <p>Nestled in the heart of [City/Area], [Banquet Hall Name] offers an exquisite venue for your special occasions. Whether you're planning a wedding reception, corporate gala, or any celebratory event, our banquet hall provides the perfect blend of elegance, sophistication, and versatility to make your event truly unforgettable.</p>
                            <h4>Features and Amenities:</h4>
                            <ul>
                                <li><strong>Grand Ballroom:</strong> Our spacious ballroom boasts high ceilings, stunning chandeliers, and customizable lighting to set the mood for your event. With flexible seating arrangements, it can accommodate intimate gatherings or large receptions with equal grace.</li>
                                <li><strong>Outdoor Terrace:</strong> Enjoy the beauty of the outdoors in our charming terrace area, ideal for cocktail hours, pre-event receptions, or al fresco dining. Surrounded by lush greenery and adorned with twinkling lights, it provides a picturesque backdrop for your celebrations.</li>
                                <li><strong>State-of-the-Art Audiovisual Equipment:</strong> From crystal-clear sound systems to high-definition projectors, we offer top-notch audiovisual equipment to enhance presentations, speeches, and entertainment performances.</li>
                                <li><strong>Catering Services:</strong> Our experienced culinary team crafts delectable menus featuring an array of cuisines to tantalize your taste buds. Whether you prefer a gourmet sit-down dinner, buffet-style feast, or themed stations, we cater to your preferences with impeccable service.</li>
                                <li><strong>Professional Event Planning Assistance:</strong> From concept to execution, our dedicated event planning professionals are committed to bringing your vision to life. With attention to detail and a passion for perfection, we ensure every aspect of your event exceeds expectations.</li>
                                <li><strong>Convenient Location and Parking:</strong> Situated in [City/Area], [Banquet Hall Name] offers easy accessibility for you and your guests, with ample parking available on-site for added convenience.</li>
                            </ul>
                            <h4>Plan Your Event with Us:</h4>
                            <p>At [Banquet Hall Name], we understand that every event is unique, and we strive to tailor our services to suit your individual needs. Whether you're hosting a glamorous wedding reception, a corporate conference, or a milestone celebration, our versatile venue and attentive staff are here to ensure your event is a resounding success.</p>
                            <p>Contact us today to schedule a tour and begin planning your unforgettable event at [Banquet Hall Name]. Let us turn your vision into reality and create cherished memories that will last a lifetime.</p>
                        </Col>
                        <Col md="4">
                            <Card>
                                <Card.Body>
                                    <div className='tour_style'>
                                        <Row>
                                            <Col md={12} className='mb-4'>
                                                <div className='tour_bx'>
                                                    <img src={tour1} alt='' />
                                                    <div className='tour_hover'>
                                                        <div className="gallery-inner">
                                                            <h5>Rainbow Banquit</h5>
                                                            <Link className="read_more dark_btn" to="#" title="">View Detail</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md={12} className='mb-4'>
                                                <div className='tour_bx'>
                                                    <img src={tour2} alt='' />
                                                    <div className='tour_hover'>
                                                        <div className="gallery-inner">
                                                            <h5>Rainbow Banquet</h5>
                                                            <Link className="read_more dark_btn" to="#" title="">View Detail</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Card.Body>
                            </Card>

                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}