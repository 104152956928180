import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import tour1 from '../images/tour-1.jpg';
import tour2 from '../images/tour-2.jpg';
import tour3 from '../images/tour-3.jpg';
import { Link } from 'react-router-dom';
export default function Tours() {
    return (
        <React.Fragment>
            <div className='py-5'>
                <Container>
                    <Row className="justify-content-center mb-3">
                        <Col md={6}>
                            <div className='title_dark text-center'>
                                <span className='sub_title'>Nearest Best Places</span>
                                <h2>Places To Visit</h2>
                            </div>
                        </Col>
                    </Row>
                    <div className='tour_style'>
                        <Row>
                            <Col md={4}>
                                <div className='tour_bx'>
                                    <img src={tour1} alt='' />
                                    <div className='tour_hover'>
                                        <div className="gallery-inner">
                                            <span><strong className="amount">₹93.00 </strong>/ Extra</span>
                                            <h5>WATER RAFTING</h5>
                                            <Link className="read_more dark_btn" to="#" title="">View Detail</Link>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className='tour_bx'>
                                    <img src={tour2} alt='' />
                                    <div className='tour_hover'>
                                        <div className="gallery-inner">
                                            <span><strong className="amount">₹93.00 </strong>/ Extra</span>
                                            <h5>WATER RAFTING</h5>
                                            <Link className="read_more dark_btn" to="#" title="">View Detail</Link>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className='tour_bx'>
                                    <img src={tour3} alt='' />
                                    <div className='tour_hover'>
                                        <div className="gallery-inner">
                                            <span><strong className="amount">₹93.00 </strong>/ Extra</span>
                                            <h5>WATER RAFTING</h5>
                                            <Link className="read_more dark_btn" to="tour-detail.html" title="">View Detail</Link>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    )
}