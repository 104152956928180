import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import UniqueVisitorCounter from './UniqueVisitorCounter'

export default function Footer() {
  return (
    <React.Fragment>
      <div className='footer_style'>
      <Container>
        <Row>
            <Col md={3}>
              <h4>Services Links</h4>
              <ul>
                <li><Link to="#">Home</Link></li>
                <li><Link to="#">Rooms & Suites</Link></li>
                <li><Link to="#">Restaurant</Link></li>
                <li><Link to="#">Spa & Wellness</Link></li>
                <li><Link to="#">About Hotel</Link></li>
                <li><Link to="#">Contact us</Link></li>
              </ul>
            </Col>
            <Col md={3}>
              <h4>Information</h4>
              <div className='d-flex gap-2 align-items-center'>
                <FontAwesomeIcon icon="fa-solid fa-phone-volume" />
                <span>
                  +91-7052-101-786<br />+91-7052-101-786
                </span>
                
              </div>
              <div className='d-flex gap-2 my-2 align-items-center'>
                <FontAwesomeIcon icon="fa-solid fa-envelope-open-text" />
                <span>info@example.com</span>
              </div>
              <div className='d-flex gap-2 align-items-center'>
                <FontAwesomeIcon icon="fa-solid fa-signs-post" />
                <span>1247/Plot No. 39, 15th Phase,
                  United States of America</span>
                
              </div>
            </Col>
            <Col md={3}>
              <h4>Unique Visitor Counter</h4>
              <UniqueVisitorCounter/>
            </Col>
            <Col md={3}>
              <iframe
                src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Ftndsoft.in%2F&tabs=timeline&width=340&height=280&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
                width="340"
                height="280"
                style={{ border: 'none', overflow: 'hidden' }}
                scrolling="no"
                frameBorder="0"
                allowFullScreen="true"
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
              ></iframe>
            </Col>
        </Row>
        </Container>
      </div>
      <Container>
        <Col className='text-center py-2'>Copyright Company Name @ 2024. All right reserved</Col>
      </Container>
    </React.Fragment>
  )
}