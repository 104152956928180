import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import OwlCarousel from 'react-owl-carousel';
import user from '../images/t.jpg';
import noimage from '../images/noimage.jpg';
export default function Testimonials() {
    const options = {
        margin: 30,
        responsiveClass: true,
        nav: true,
        dots: false,
        autoplay: false,
        navText: ["Prev", "Next"],
        smartSpeed: 1000,
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 1,
            },
            600: {
                items: 1,
            },
            700: {
                items: 1,
            },
            1000: {
                items: 2,

            }
        },
    };
    return (
        <React.Fragment>
            <div className='py-5 testimonials_styles'>
                <Container>
                    <Row className="justify-content-center mb-3">
                        <Col md={6}>
                            <div className='title_dark text-center'>
                                <span className='sub_title'>WHAT SAYS FOR CUSTOMER</span>
                                <h2>What Client's Say?</h2>
                            </div>
                        </Col>
                    </Row>
                    <div className='testimonials_inn'>
                        <OwlCarousel className="slider-items owl-carousel" {...options}>
                            <div className="item article-items">
                                <div className='text-mbox aticle-box'>
                                    <img src={user} alt=''/>
                                    <div className="test_comment">Praesent cursus nulla non arcu tempor, ut egestas elit tempus. In ac ex fermentum, gravida felis nec, tincidunt ligula. Sed dapibus mauris ullamcorper. luctus magna.</div>
                                    <h5>Jina Nillson</h5>
                                </div>
                            </div>
                            <div className="item article-items">
                                <div className='text-mbox aticle-box'>
                                    <img src={noimage} alt='' />
                                    <div className="test_comment">Praesent cursus nulla non arcu tempor, ut egestas elit tempus. In ac ex fermentum, gravida felis nec, tincidunt ligula. Sed dapibus mauris ullamcorper. luctus magna.</div>
                                    <h5>Jina Nillson</h5>
                                </div>
                            </div>
                          
                        </OwlCarousel>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    )
}